export const commonDict = {

        taskTypeConfig: {
            '0': {color: "#4A90E2", label: "个人开户", show: true},        // 信任蓝
            '1': {color: "#34C759", label: "企业开户", show: true},        // 企业绿
            '2': {color: "#FF9500", label: "个人贷款申请", show: true},    // 警示橙
            '3': {color: "#5856D6", label: "企业贷款申请", show: true},    // 商务紫
            '4': {color: "#5AC8FA", label: "归集协议添加", show: true},    // 连接青
            '5': {color: "#007AFF", label: "个人开户信息变更", show: true},// 深信任蓝
            '6': {color: "#28CD41", label: "企业开户信息变更", show: true},// 深企业绿
            '7': {color: "#AF52DE", label: "个人自助开户", show: true},    // 自助紫
            '8': {color: "#32ADE6", label: "企业自助开户", show: true},    // 自助蓝
            '9': {color: "#8E8E93", label: "账户销户", show: true},        // 中性灰
            '10': {color: "#FF3B30", label: "余额支付", show: true},       // 支付红
            '11': {color: "#FF6905", label: "融资支付", show: true},      // 融资橙
            '12': {color: "#FF2D55", label: "清分支付", show: true},       // 清分红
            '13': {color: "#A2845E", label: "非银垫资", show: true},       // 金融棕
            '14': {color: "#68758E", label: "非银清算", show: true},       // 清算灰
            default: {color: "#C7C7CC", label: "未知", show: false},       // 浅中性灰
        },
        taskStatusConfig: {
            '0': {color: "#4A90E2", label: "待处理", show: true},
            '1': {color: "#34C759", label: "已处理", show: true},
            '2': {color: "#FF2D55", label: "处理失败", show: true},
            default: {color: "#C7C7CC", label: "未知", show: false},

        },
        goodsTypeConfig: {
            '11': {color: "#DB5409", label: "商品类", show: true, addShow: true},
            '10': {color: "#DB5409", label: "商品类（特殊）", show: true, addShow: true},

            '20': {color: "#FF6B6B", label: "娱乐类", show: true, addShow: false},
            '30': {color: "#DBC309", label: "资金类", show: true, addShow: true},

            '40': {color: "#01D1DB", label: "代销类", show: true, addShow: true},
            '41': {color: "#01D1DB", label: "代销类(特殊)", show: true, addShow: false},

            '50': {color: "#7509DB", label: "权益类", show: true, addShow: true},
            '51': {color: "#6d8f29", label: "物流类", show: true, addShow: true},      //

            default: {color: "#C7C7CC", label: "未知", show: false, addShow: false},
        },

    //     贷款状态（0、开户，1、正常，2、逾期，3、结清，4、销户，5、申报核销，6、部分核销，7、全部核销）
        loanStatusConfig: {
            '0': {color: "#4A90E2", label: "开户", show: true},
            '1': {color: "#34C759", label: "正常", show: true},
            '2': {color: "#FF9500", label: "逾期", show: true},
            '3': {color: "#FF2D55", label: "结清", show: true},
            '4': {color: "#8E8E93", label: "销户", show: true},
            '5': {color: "#FF3B30", label: "申报核销", show: true},
            '6': {color: "#FF6905", label: "部分核销", show: true},
            '7': {color: "#FF2D55", label: "全部核销", show: true},
            default: {color: "#C7C7CC", label: "未知", show: false },

        },
    // 贷款形态（0、正常 1、逾期 2、呆滞 3、呆账 4、部分逾期 5、部分呆滞）
        loanFormConfig: {
            '0': {color: "#4A90E2", label: "正常", show: true},
            '1': {color: "#FF9500", label: "逾期", show: true},
            '2': {color: "#FF2D55", label: "呆滞", show: true},
            '3': {color: "#8E8E93", label: "呆账", show: true},
            '4': {color: "#FF3B30", label: "部分逾期", show: true},
            '5': {color: "#FF6905", label: "部分呆滞", show: true},
            default: {color: "#C7C7CC", label: "未知", show: false },
        },
    // 还款方式：1、等本 2、等额 3、按期付息、按期还本 4、一次性还本付息 5、按期付息，任意还本 6、任意还本，利随本清 7、按期付息，到期一次性还本
        repaymentMethodConfig: {
            '1': {color: "#4A90E2", label: "等本", show: true},
            '2': {color: "#34C759", label: "等额", show: true},
            '3': {color: "#FF9500", label: "按期付息、按期还本", show: true},
            '4': {color: "#FF2D55", label: "一次性还本付息", show: true},
            '5': {color: "#8E8E93", label: "按期付息，任意还本", show: true},
            '6': {color: "#FF3B30", label: "任意还本，利随本清", show: true},
            '7': {color: "#FF6905", label: "按期付息，到期一次性还本", show: true},
            default: {color: "#C7C7CC", label: "未知", show: false },

        }

    }
;
