<template>
  <div>
    <a-card style="margin:20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span>
            <a-icon type="right"/>
            <span>账户信息</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">返回</a-button>
      </template>

      <div class="Accountview">
        <!-- 用户信息区域 -->

        <div class="userinfo">
          <div class="userinfo-title">
            {{ this.userinfo.accountName }}
          </div>
          <a-menu
              v-model="selectedKey"
              style="display: flex; flex-wrap: nowrap; white-space: nowrap; overflow-x: auto; "
              :default-open-keys="openKey"

          >
            <a-menu-item v-for="(item, index) in accountInfoList" :key="index" @click="clickMenuItem($event)"
                         style="flex: none;">
              <a-card style=" width: 300px; height: 150px;border-radius: 8px;padding: 0 8px;"
                      v-if="item.account_class !== '6' || $authority('A2BalanceVisible')"
                      :style="selectedKey == index ? 'border-color:#1890ff' : 'border-color:#e8e8e8'">

                <a-tag :color="tagColor(item.account_class)">{{ tagText(item.account_class) }}</a-tag>

                <span>{{ item.account_no }}</span>
                <a-button type="link" v-if="item.account_class === '7'" @click="toDetail(item.account_no)">明细</a-button>
                <div class="box-left">

                </div>
                <div class="box-left alias">
                  <p v-if="!remarkEditing[index]">
                    别名: {{ item.remark }}
                    <a-button icon="edit" type="link" size="small" @click.stop="editRemark(item, index)"></a-button>
                  </p>
                  <p v-if="remarkEditing[index]">
                    别名:
                    <a-input style="width: 150px" ref="remark" :value="item.remark" size="small" @click.stop=""
                             @blur="modRemark(item, index, $event)"/>
                  </p>
                </div>

                <div class="balance-box">
                  <div class="box-left">
                    余额:
                  </div>
                  <Balance :amount="item.cur_balance" style="font-weight: bold; "></Balance>
                  <!-- 提现 -->
                  <withdraw-btn v-if="Number(item.account_class) === 0"
                                :channel-no="form.channel_no" :account-no="item.account_no"
                                @ok="getAccountNum"></withdraw-btn>
                  <guarantee-withdraw-btn
                      v-if="Number(item.account_class) === 2"
                      :account-no-a1="userinfo.accountNo" :account-no-a6="item.account_no" :app-no="form.app_no"
                      :channel-no="form.channel_no"
                      @ok="getAccountNum"
                  ></guarantee-withdraw-btn>
                  <guarantee-deposit-btn
                      v-if="Number(item.account_class) === 2"
                      :account-no-a1="userinfo.accountNo" :account-no-a6="item.account_no" :app-no="form.app_no"
                      :channel-no="form.channel_no"
                      @ok="getAccountNum"
                  ></guarantee-deposit-btn>
                </div>
              </a-card>

            </a-menu-item>
          </a-menu>
        </div>
        <!-- 表格 -->
        <!-- table 区域 -->

        <div class="tablebox">
          <!-- 搜索查询 -->
          <!-- 表格 -->
          <div class="searchbox">
            <div class="lefttime">
              <span>交易时间:</span>
              <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" @change="onChange"/>
              <a-radio-group v-model="datater" @change="dataonChange">
                <a-radio-button :value="1">
                  今天
                </a-radio-button>
                <a-radio-button :value="2">
                  昨天
                </a-radio-button>
                <a-radio-button :value="3">
                  近七天
                </a-radio-button>
                <a-radio-button :value="4">
                  当月
                </a-radio-button>
                <a-radio-button :value="5">
                  上月
                </a-radio-button>
              </a-radio-group>
            </div>

            <div class="right">
              <a-button type="primary" @click="search">
                查询
              </a-button>
              <a-button @click="reset">重置</a-button>
            </div>
          </div>
          <!-- 下载 -->

          <div class="uploadbox">
            <div>
              <a-button type="link" icon="eye" @click="detailPV">统计视图</a-button>
              <a-button type="link" icon="download" @click="returnBillDownload" v-if="$authority('receiptDownload')">
                回单
              </a-button>
              <a-button type="link" icon="download" @click="checkBillDownload" v-if="$authority('statementDownload')">
                对账单
              </a-button>
              <a-button type="link" icon="download" @click="Detailexport" v-if="$authority('Detailexport')">
                明细表
              </a-button>
            </div>
          </div>
          <div class="tableBody">
            <a-table :rowKey="(record, index) => `${record.id}`" :columns="columns" :data-source="data" size="small"
                     :scroll="{ x: 1800, y: 500 }"
                     :row-selection="{ selectedRowKeys: selectedRowKeys, hideDefaultSelections: true, onChange: onSelectChange, }"
                     :customRow="clickrow"
                     :pagination="false">
            </a-table>
          </div>
        </div>

      </div> <!-- 分页部分 -->
      <div class="paginationBox">
        <!-- <a-pagination v-model.trim="form.page" :page-size-options="pageSizeOptions" :total="total" show-size-changer
            :page-size="form.row" @change="pagechange" @showSizeChange="onShowSizeChange" :item-render="itemRender"
            show-quick-jumper>

          </a-pagination> -->
      </div>
    </a-card>
    <a-modal v-model="detailPvVisible" width="1000px" @ok="detailPvVisible=false" :closable="false">
      <a-table :columns="detailPVColumn" :data-source="detailPVData" size="small"> </a-table>
      <a-descriptions :column="3" bordered size="small">
        <a-descriptions-item label="总收入">{{ summaryPVData.dCount }}笔
          <Balance :amount="summaryPVData.dSum" dcflag="d" :inline="true"/>
        </a-descriptions-item>
        <a-descriptions-item label="总支出">{{ summaryPVData.cCount }}笔
          <Balance :amount="summaryPVData.cSum" dcflag="c" :inline="true"/>
        </a-descriptions-item>
        <a-descriptions-item label="时间">
          {{this.form.start_date}} ~{{this.form.end_date}}
        </a-descriptions-item>
      </a-descriptions>
      <template slot="footer">
        <a-button type="primary" @click="detailPvVisible=false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal v-model="loanDetailVisible" width="1200px" @ok="detailPvVisible=false" :closable="false">
      <a-table :columns="loanDetailColumn" :data-source="loanDetailData" size="small" :pagination="false">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" size="small"  @click="detail(record)"
                    aria-label="明细" icon="unordered-list">
          </a-button>
        </template>
      </a-table>
      <template slot="footer">
        <a-button type="primary" @click="loanDetailVisible=false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal v-model="repayVisible" width="1200px" @ok="repayVisible=false" :closable="false">
      <a-table :columns="repayColumns" :data-source="repayData" size="small" :pagination="false">

      </a-table>
      <template slot="footer">
        <a-button type="primary" @click="repayVisible=false">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Balance from '@/view/components/Balance.vue'
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import {groupBy} from "@/utils/commonMethods";
import Vue from "vue";
import InputBalance from "@/view/components/InputBalance.vue";
import PaymentSms from "@/view/components/PaymentSms.vue";
import WithdrawBtn from "@/view/components/WithdrawBtn.vue";
import GuaranteeWithdrawBtn from "@/view/components/GaranteeWithdrawBtn.vue";
import GuaranteeDepositBtn from "@/view/components/GaranteeDepositBtn.vue";
import {nbiDict} from "@/utils/bizDict/nbiDict";
import {commonDict} from "@/utils/bizDict/commonDict";

// import moment from "moment"

export default {
  components: {
    GuaranteeDepositBtn,
    GuaranteeWithdrawBtn,
    WithdrawBtn,
    PaymentSms,
    InputBalance,
    CountdownBtn,
    Balance
  },
  data() {
    return {
      selectedKey: [0],
      openKey: [],
      accountInfoList: [],
      userinfo: {},
      bailAccountInfo: {},
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      searchtime: [],
      selectedRowKeys: [],
      selectedRows: [],
      show: true,
      detailPvVisible: false,
      loanDetailVisible: false,
      repayVisible: false,
      datater: 4,// 日期
      form: {
        page: 0,
        channel_no: "", // 渠道编号
        app_no: '',//	应用编号	
        account_no: "",// 账号
        start_date: '',
        end_date: '',
      },
      remarkEditing: [],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) => `${index + 1}`,
          align: 'center',
          width: 60,
        },
        {
          title: '流水号',
          dataIndex: 'serial_no',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '记账日期',
          dataIndex: 'account_date',
          align: 'center',
          width: 120,
        },
        {
          title: '交易类型',
          dataIndex: 'trans_type',
          align: 'center',
          width: 80,

          customRender: (text) => {
            if (Number(text) == 0) {
              return '其他'
            } else if (Number(text) == 1) {
              return '开户'
            } else if (Number(text) == 2) {
              return '修改'
            } else if (Number(text) == 3) {
              return '销户'
            } else if (Number(text) == 4) {
              return '存款'
            } else if (Number(text) == 5) {
              return '取款'
            } else if (Number(text) == 6) {
              return '转入'
            } else if (Number(text) == 7) {
              return '转出'
            } else if (Number(text) == 8) {
              return '撤销'
            } else {
              return text;
            }
          },
        },
        {
          title: '对方户名',
          dataIndex: 'opp_account_name',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        {
          title: '对方账户',
          dataIndex: 'opp_account_no',
          align: 'center',
          width: 160,
        },
        {
          title: '交易金额',
          dataIndex: 'trans_amount',
          align: 'center',
          customRender: (text, row) => {
            let dcflag = ''
            if (['4', '6'].indexOf(row.trans_type) >= 0) dcflag = "d"
            if (['5', '7'].indexOf(row.trans_type) >= 0) dcflag = "c"
            return <Balance amount={text} dcflag={dcflag}></Balance>
          },
          width: 140,
        },
        {
          title: '交易后余额',
          dataIndex: 'balance',
          align: 'center',
          width: 140,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: '交易时间',
          dataIndex: 'trade_time',
          align: 'center',
          width: 180,
        },
        {
          title: '摘要',
          dataIndex: 'remark',
          align: 'center',
          ellipsis: true,
        },

      ],
      detailPVColumn: [
        {
          title: '对方户名',
          dataIndex: 'opp_account_name',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        {
          title: '交易备注',
          dataIndex: 'remark',
          align: 'center',
          width: 400,
          ellipsis: true,
        }, {
          title: '交易金额合计',
          dataIndex: '合计#trans_amount',
          align: 'center',
          width: 140,
          customRender: (text, row) => {
            let dcflag = ''
            if (['4', '6'].indexOf(row.trans_type) >= 0) dcflag = "d"
            if (['5', '7'].indexOf(row.trans_type) >= 0) dcflag = "c"
            return <Balance amount={text} dcflag={dcflag}></Balance>
          },
        }
      ],
      // 表格数据
      data: [],
      //贷款明细数据
      loanDetailData: [],
      loanDetailColumn: [
        {
          title: '活期钱包',
          dataIndex: 'accountNo',
          align: 'center',
          width: 100,
          ellipsis: true,
        },
        {
          title: '贷款账号',
          dataIndex: 'loanacctno',
          align: 'center',
          width: 300,
          ellipsis: true,
        },
        {
          title: '发放日期',
          dataIndex: 'loanDate',
          align: 'center',
          width: 100,
          ellipsis: true,
        },
        {
          title: '发放金额',
          dataIndex: 'loanAmount',
          align: 'center',
          width: 100,
          customRender: (text) => {
            return <Balance amount={text}/>
          }
        },
        {
          title: '已还本金',
          dataIndex: 'repayAmountBj',
          align: 'center',
          width: 100,
          customRender: (text) => {
            return <Balance amount={text}/>
          }
        },
        {
          title: '已还利息',
          dataIndex: 'repayAmountLx',
          align: 'center',
          width: 100,
          customRender: (text) => {
            return <Balance amount={text}/>
          }
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        }
      ],
      repayData: [],
      repayColumns: [
        {title: '贷款类型', dataIndex: 'loantp', key: 'loantp'},
        {title: '本月应还本金', dataIndex: 'crnrpyblamtb', key: 'crnrpyblamtb'},
        {title: '本月应还利息', dataIndex: 'crnrpyblamtl', key: 'crnrpyblamtl'},
        {title: '剩余本金', dataIndex: 'rmanpnpamt', key: 'rmanpnpamt'},
        {title: '还款日', dataIndex: 'rpybldt', key: 'rpybldt'},
        // {title: '合同编号', dataIndex: 'ctrno', key: 'ctrno'},
        // {title: '贷款账号', dataIndex: 'loanacctno', key: 'loanacctno'},
        // {title: '币种', dataIndex: 'buyexgccycd', key: 'buyexgccycd'},
        // {title: '年利率', dataIndex: 'yrintrate', key: 'yrintrate'},
        {
          title: '贷款状态',
          dataIndex: 'loanst',
          key: 'loanst',
          customRender: (text) => {
            const config = commonDict.loanStatusConfig[text] || commonDict.loanStatusConfig.default;
            return <a-tag color={config.color}>{config.label}</a-tag>
          }
        },
        {
          title: '还款状态',
          dataIndex: 'loantp1',
          key: 'loantp1',
          customRender: (text) => {
            const config = commonDict.loanFormConfig[text] || commonDict.loanFormConfig.default;
            return <a-tag color={config.color}>{config.label}</a-tag>
          }
        },
        {title: '发放金额', dataIndex: 'dstrbtintrate', key: 'dstrbtintrate'},
        {title: '发放日期', dataIndex: 'lndngdt', key: 'lndngdt'},
        {
          title: '还款方式',
          dataIndex: 'repymtmd',
          key: 'repymtmd',
          customRender: (text) => {
            const config = commonDict.repaymentMethodConfig[text] || commonDict.repaymentMethodConfig.default;
            return <a-tag color={config.color}>{config.label}</a-tag>
          }
        },
        {title: '还款日期', dataIndex: 'loanexprddt', key: 'loanexprddt'}
      ],


      total: 0, // 总数

    }
  },

  created() {
    // 获取存储的用户信息
    this.userinfo = this.$store.state.Accountmanagementinfo
    this.form.app_no = this.$store.state.Accountmanagementinfo.appNo
    this.form.channel_no = this.$store.state.Accountmanagementinfo.channelNo
    this.form.account_no = this.$store.state.Accountmanagementinfo.accountNo
    const currentTime = dayjs(); // 获取当前日期
    const startOfMonth = dayjs().startOf('month'); // 获取本月月初时间
    this.form.start_date = startOfMonth.format('YYYY-MM-DD')
    this.form.end_date = currentTime/*.add(1, 'day')*/.format('YYYY-MM-DD')
    this.searchtime[0] = this.form.start_date
    this.searchtime[1] = this.form.end_date
    // 获取账号
    this.getAccountNum()
    // 获取交易详细
    this.search()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },

  computed: {
    tagColor() {
      return function (type) {
        if (type == '0') {
          return 'green'
        } else if (type == '6') {
          return 'blue'
        } else if (type == '8') {
          return 'blue'
        } else if (type == '7') {
          return 'purple'
        } else if (type == '2') {
          return 'yellow'
        }
      }
    },
    tagText() {
      return function (type) {
        if (type == '0') {
          return '活期A1'
        } else if (type == '6') {
          return '冻结A2'
        } else if (type == '7') {
          return '贷款A3'
        } else if (type == '2') {
          return '保证A6'
        } else if (type == '8') {
          return '分户A7'
        }
      }
    },
    detailPVData() {
      const pvData = groupBy(this.data ?? [], ['trans_type', 'remark', 'opp_account_name'], {sum: ['trans_amount']})
          .filter(item => item['合计#trans_amount'])
          .sort((b, a) => {
            if (a['trans_type'] === b['trans_type']) return a['合计#trans_amount'] - b['合计#trans_amount']
            else return Number(a['trans_type']) - Number(b['trans_type'])
          })
      return pvData
    },
    summaryPVData() {
      let dCount = 0, dSum = 0, cCount = 0, cSum = 0;
      (this.data ?? []).forEach(item => {
        if (['4', '6'].indexOf(item.trans_type) >= 0) {
          dCount++
          dSum += Number(item.trans_amount)
        } else if (['5', '7'].indexOf(item.trans_type) >= 0) {
          cCount++
          cSum += Number(item.trans_amount)
        }
      });
      return {dCount, dSum, cCount, cSum}
    }
  },
  methods: {
    editRemark(item, index) {
      Vue.set(this.remarkEditing, index, true)
      let that = this
      this.$nextTick(() => {
        let el = that.$refs.remark[0]
        el.focus()
        el.select()
      })
    },
    // 修改备注
    modRemark(item, index, event) {
      const newRemark = event.target.value;
      item.remark = newRemark;
      Vue.set(this.remarkEditing, index, false)
      this.api.modRemarkApi({
        accountNo: item.account_no,
        remark: event.target.value
      })
    },


    dataonChange($event) {
      //  今天 1 昨天2 近七天 3 当月4 上月5 

      this.$set(this.searchtime, 0, dayjs().format('YYYY-MM-DD')); // 替换数组中的某一项
      if ($event.target.value == 1) {
        this.form.start_date = dayjs().format('YYYY-MM-DD')
        this.form.end_date = dayjs().format('YYYY-MM-DD')
      } else if ($event.target.value == 2) {
        this.form.start_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      } else if ($event.target.value == 3) {
        this.form.start_date = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      } else if ($event.target.value == 4) {
        this.form.start_date = dayjs().startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      } else if ($event.target.value == 5) {
        this.form.start_date = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs(this.form.start_date).endOf('month')/*.add(1, 'day')*/.format('YYYY-MM-DD')
      }
      // 开始时间
      this.$set(this.searchtime, 0, this.form.start_date);
      // 结束时间
      this.$set(this.searchtime, 1, this.form.end_date);
      this.getqueryTradeDetail()
    },
    // 获取数据
    async getqueryTradeDetail() {

      const res = await this.api.queryTradeDetailApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.total = res.data.total
      this.form.page = res.data.current;
      // this.form.row = res.data.size;

      this.data = res.data.detail_list
    },
    async getAccountNum() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.userinfo.channelNo,
        account_no: this.userinfo.majorAccountNo || this.userinfo.accountNo,
        relation_acct: this.userinfo.accountRelation,
      })
      if (res.code !== 200) {
        return this.$message.error(res.info);
      }
      this.accountInfoList = res.data.detail_list
      let keyarr = []
      this.accountInfoList.map((item, index) => {
        keyarr.push(index)
      })
      this.openKey = keyarr
    },
    // 明细导出
    async Detailexport() {
      // accountTradeDetailExportApi
      let data = {
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      }
      const res = await this.api.accountTradeDetailExportApi(data)
      this.$uploadfile.ExcelDownload('交易明细', res)
    },


    search() {
      this.getqueryTradeDetail()
    },
    // 时间选择器
    onChange() {
      this.form.start_date = this.searchtime[0]
      // this.form.end_date = this.searchtime[1]
      this.form.end_date = dayjs(this.searchtime[1])/*.add(1, 'day')*/.format('YYYY-MM-DD')
      this.datater = ''
    },
    // 查看客户详情
    Customerdetail() {
      // 个人
      if (Number(this.userinfo.accountProperty) == 1) {
        this.$router.push({
          path: "/cust/personage/addCust",
          query: {
            custnum: this.userinfo.custnum,
            type: 'view'
          }
        })
      }
      // 企业
      if (Number(this.userinfo.accountProperty) == 2) {
        this.$router.push({
          name: 'addEnterprise',
          query: {
            custnum: this.userinfo.custnum,
            type: 'view',
          },
        })
      }


    },
    // 统计视图
    detailPV() {
      this.detailPvVisible = true
    },

    async returnBillDownload() {
      if (this.selectedRows && this.selectedRows.length == 1) {
        this.returnSingleBillDownload()

      } else {
        this.returnBatchbilldownload()

      }

    },


    // 回单下载
    async returnBatchbilldownload() {
      if (this.isProcessing) {
        this.$message.warning("批量下载中，请等待当前操作完成！");
        return;
      }

      this.isProcessing = true;
      try {

        for (const item of this.data) {
          if (item.original_serialno) {
            this.selectedRows = [item];
            await this.returnSingleBillDownload();
          }
        }
        this.$message.success("所有账单已成功下载！");
      } catch (error) {
        console.error("批量下载失败:", error);
        this.$message.error("批量下载过程中发生错误！");
      } finally {
        this.isProcessing = false;
      }
    },

    async returnSingleBillDownload() {
      if (this.selectedRows.length == 0 || !this.selectedRows) {
        this.$message.warning('请先选择一条记录',);
        return
      }
      // returnBillDownloadApi
      let data = {
        id: this.selectedRows[0].id,
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        original_serialno: this.selectedRows[0].original_serialno

      }
      const res = await this.api.returnBillDownloadApi(data)
      console.log(res)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      if (res.data.fileList == null) {

        this.$message.warning('此账号暂无回单信息')
      }
      res.data.fileList?.forEach(item => {

        this.$uploadfile.PDFDownload('回单-' + data.original_serialno, item.file_data, false)
      });


    },

    // 对账单下载
    async checkBillDownload() {
      let data = {
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      }
      const res = await this.api.checkBillDownloadApi(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$uploadfile.PDFDownload('对账单下载', res.data.file_data, true)

    },
    reset() {
      this.searchtime = []
      this.form.start_date = ''
      this.form.pageNo = 0
      this.form.end_date = ''
    },
    // 确认提现
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {

      if (type === 'prev') {
        return <a>上一页</a>;
      } else if (type === 'next') {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    // pagechange(val) {

    //   this.form.page = val;
    //   this.getcustaccountPage()
    // },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {


      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = [selectedRows[0]]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if (id === this.selectedRowKeys[0]) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)
              this.selectedRows = [record]
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {

      this.form.row = pageSize;
      this.getcustaccountPage()
    },

    clickMenuItem(item) {
      this.accountInfoList.forEach((obj, ind) => {
        if (item.key === ind) {
          if (this.form.account_no !== obj.account_no) {
            this.form.account_no = obj.account_no
            this.form.page = 0
            this.search()
          }
        }
      })
    },

    toDetail: async function (account_no) {
      console.log('account_no', account_no)
      if (!this.accountInfoList || !Array.isArray(this.accountInfoList)) {
        this.$message.error("账户信息列表未加载");
        return;
      }

      const account = this.accountInfoList.filter((item) => item.account_class === '0');

      try {
        const res = await this.api.collectorderscreditListByAccountNo({accountNo: account[0]?.account_no});
        if (res.code !== 200) {
          this.$message.error(res.info);
          return;
        }
        this.loanDetailData = res.data;
        this.loanDetailVisible = true;
      } catch (error) {
        console.error("API 请求失败:",error);
        this.$message.error("请求失败，请稍后重试");
      }
    },

    detail: async function (record) {
      console.log('detail record', record)
      const res = await this.api.creditmanagercreditRepayQueryV2({account_no:record.accountNo});
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      console.log("detail res",res)
      this.repayData = res.data.recode_list;
      this.repayVisible = true;
    },

    toBack() {
      // 返回列表页
      let toURl = ''
      if (this.userinfo.arrList) {
        // 账户列表页
        toURl = '/wallet/accountlist'
      } else {
        // 账户管理
        toURl = '/wallet/accountmanagement'
      }
      this.$router.push(toURl)
    }
  },
  watch: {
    accountInfoList(val) {
      this.remarkEditing = (val ?? []).map(acct => false)
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}


.ant-menu-horizontal > .ant-menu-item,
/deep/ .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 10px solid transparent;
  transition: color 0
}

.ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid #1890ff;
}

/deep/ .ant-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.Accountview {
  width: 100%;

  .userinfo {
    position: relative;
    width: 100%;
    min-height: 200px;

    .userinfo-title {
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      margin: 0 0 20px 20px;
    }

    .alias {
      p {
        color: grey;
        font-size: 12px;
      }

      button {
        visibility: hidden;
      }
    }

    .alias:hover {
      button {
        visibility: visible;
      }
    }

    .balance-box {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;

      .box-left {
        margin-right: 35px;
        font-weight: bold;
        color: #262626;
        font-size: 16px;
      }
    }

    .content {
      width: 100%;
      padding: 0 24px;

      .mian {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        width: 100%;
        padding-bottom: 10px;
        min-height: 138px;
        box-shadow: inset 0 0 1px 1px #f2f2f2;

        .toptext {


          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 20px;

          .item {
            width: 188px;
            margin-bottom: 25px;

            span {
              &:first-child {
                margin-right: 10px;
              }
            }


            &:nth-child(2) {
              width: 300px;
            }

            &:nth-child(4) {
              width: 200px;
            }
          }
        }

        .Currentbalance {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;
          padding-right: 60px;

          > div {
            display: flex;
          }

          .Current {
            // width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 18px;
              font-weight: 700;
              color: #333333;
              margin-right: 10px;

              &:last-child {
                // margin-left: 5px;
                // margin-top: 5px;
                font-weight: 600;
              }
            }
          }

          .usable {
            margin-left: 210px;
            width: 140px;
            display: flex;
            justify-content: space-between;

            span {
              font-size: 18px;
              font-weight: 700;

              &:last-child {
                font-weight: 600;
                margin-top: 5px;
              }
            }
          }

          .Customerdetails {


            button {
              height: 24px;
            }
          }
        }

        .Yesterdaybalance {
          span {
            font-size: 13px;
            color: #aaaaaa;
          }
        }
      }
    }

    .Breadcrab {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 35px;
      display: flex;
      padding-left: 24px;
      align-items: center;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 10px;
      padding-bottom: 10px;
      justify-content: space-between;
      padding-right: 25px;

      .left {
        span {
          &:first-child {
            font-size: 15px;
            font-weight: 700;
          }

          // &:last-child {}
        }
      }
    }
  }

  .tablebox {
    padding: 0 24px;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .searchbox {
      min-height: 44px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-top: 15px;


      .lefttime {
        .ant-calendar-picker {
          margin-left: 20px;
          margin-right: 20px;
          width: 200px;
          border-radius: 6px;
          height: 24px !important;
        }
      }

      .right {
        margin-left: 100px;

        button {
          height: 32px;
        }
      }
    }

    .uploadbox {
      display: flex;
      justify-content: flex-end;

      button {
        height: 28px;
      }

      margin-bottom: 20px;
    }
  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-menu-vertical > .ant-menu-item,
/deep/ .ant-menu-vertical > .ant-menu-submenu {
  height: 170px;
  padding: 10px;
}


</style>
